<template>
  <main class="bg-gray-200">
    <section class="px-2 md:px-0 py-4" v-if="isManager">
      <div class="container">
        <!-- prettier-ignore -->
        <nav
          class="breadcrumb"
          aria-label="breadcrumbs"
        >
          <ul>
            <router-link tag="li" :to="{ name: 'home' }" ><a>{{ $t('breadcrumb_home') }}</a></router-link>
            <router-link class="is-hidden-mobile" tag="li" :to="{ name: 'companies' }" ><a>{{ $t('breadcrumb_companies') }}</a></router-link>
            <router-link tag="li" :to="{ name: 'company', params: { cid } }"><a><company-name :company="company"/></a></router-link>
            <router-link class="is-hidden-mobile" tag="li" :to="{ name: 'accounts-org', params: { cid, oid: 'default' } }"><a>{{ $t('breadcrumb_accounts') }}</a></router-link>
            <router-link tag="li" :to="{ name: 'account', params: { cid, aid } }"><a><account-name :account="account"/></a></router-link>
            <router-link tag="li" :to="{ name: 'events', params: { cid, aid } }"><a>{{ $t('breadcrumb_events') }}</a></router-link>
          </ul>
        </nav>
      </div>
    </section>
    <sqr-progress v-show="loading" />
    <sqr-error :error="loadError" />
    <section class="section">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-12-mobile is-8-tablet is-6-desktop">
            <div class="is-clearfix pb-4">
              <div class="buttons is-pulled-right">
                <sqr-router-link
                  label="events_planning"
                  color="white"
                  size="medium"
                  :to="{ name: 'planning', params: { cid, oid: 'default' } }"
                  v-if="viewPlanning || isManager"
                />
                <sqr-router-link
                  icon="plus"
                  label="events_add"
                  color="primary"
                  size="medium"
                  :to="{ name: 'event-add', params: { cid, aid } }"
                />
              </div>
            </div>
            <event-card
              class="mb-8 hover:shadow-xl"
              v-for="event in records"
              :key="event.id"
              :event="event"
              @remove="remove({ id: $event.id })"
            />
          </div>
        </div>
      </div>
    </section>
    <section class="px-4 md:px-0 pt-2 border-t bg-white sticky bottom-0">
      <div class="container">
        <sqr-fire-page
          v-bind="pagination"
          @page-set="pageSet"
          @page-size="pageSize"
        />
      </div>
    </section>
  </main>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

import account from './account';

import SqrError from '@/sqrd/SqrError';
import SqrProgress from '@/sqrd/SqrProgress';
import SqrFirePage from '@/sqrd/SqrFirePage';
import SqrRouterLink from '@/sqrd/SqrRouterLink';

import CompanyName from '@/components/CompanyName';
import AccountName from '@/components/AccountName';
import EventCard from '@/components/EventCard';

export default {
  name: 'Events',
  mixins: [account],
  components: {
    SqrError,
    SqrProgress,
    SqrRouterLink,
    SqrFirePage,
    EventCard,
    CompanyName,
    AccountName,
  },
  computed: {
    ...mapState('events', ['records', 'loading', 'loadError']),
    ...mapGetters('events', ['pagination']),
    ...mapGetters('perms', ['isManager', 'viewPlanning']),
  },
  mounted() {
    this.sub({
      path: ['companies', this.cid, 'accounts', this.aid, 'events'],
      pageSize: 4,
      orderBy: [['startDate', 'asc']],
      where: [['startDate', '>=', '2020-02-01']],
    });
  },
  methods: {
    ...mapActions('events', ['sub', 'pageSet', 'pageSize']),
  },
};
</script>
