import { mapState, mapMutations, mapActions } from 'vuex';
import company from './company';

export default {
  mixins: [company],
  props: {
    aid: String,
  },
  computed: {
    ...mapState('account', {
      account: 'record',
      aLoading: 'loading',
      aLoadError: 'loadError',
    }),
  },
  mounted() {
    this.aLoad();
  },
  methods: {
    ...mapActions('account', { aSub: 'sub' }),
    ...mapMutations('accountsRecent', { aPush: 'push' }),
    async aLoad() {
      try {
        await this.aSub({
          path: ['companies', this.cid, 'accounts'],
          id: this.aid,
        });
        this.aPush({ id: this.aid, item: this.account });
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
  watch: {
    '$route.params.aid'() {
      this.aLoad();
    },
  },
};
